import { Link } from "react-router-dom";
import aboutImg from "../../assets/images/luudu_sneakers.jpg";

export const About = () => {
  return (
    <main className="about_layout my-0 -!mt-[2.5rem] md:mt-0 md:my-10 px-4 sm:px-6 lg:px-8">
      <div className="about_layout_content">
        <h2 className="web_heading bar_above mb-4 text-xl sm:text-2xl md:text-3xl lg:text-4xl">
          About us
        </h2>
        <p className="web_paragraph_1 mt-2 text-sm sm:text-base md:text-lg">
          At Luudu, we&apos;re revolutionizing the way you shop for footwear
          through innovative group campaigns that harness the power of
          collective buying. By coming together to support campaigns, you not
          only secure significant discounts on high-quality footwear but also
          foster a sense of community among like-minded enthusiasts. Every time
          you back a campaign, you&apos;re participating in a transparent and
          rewarding process that ensures you receive the items you desire once
          production goals are met.
        </p>
        <p className="web_paragraph_1 mt-2 text-sm sm:text-base md:text-lg">
          But our mission goes beyond just footwear. Your support for Luudu is
          instrumental in helping us launch{" "}
          <Link
            to={"https://www.luudu.com"}
            target="_blank"
            className="underline"
          >
            Luudu
          </Link>
          , a social commerce platform designed specifically for Africa. By
          empowering consumers to partner together and negotiate better prices
          on everyday household items,{" "}
          <Link
            to={"https://www.luudu.com"}
            target="_blank"
            className="underline"
          >
            Luudu
          </Link>{" "}
          aims to tackle the challenges of fluctuating prices and improve
          purchasing power across the continent.
        </p>
        <p className="web_paragraph_1 mt-2 text-sm sm:text-base md:text-lg">
          Together, we can create meaningful change, enhancing the lives of
          individuals and families by making essential goods more accessible and
          affordable. Join us on this journey to uplift communities and make a
          difference—one campaign at a time.
        </p>
      </div>

      <div className=" md:my-0 flex items-center justify-center rounded-lg">
        <iframe
          width="100%"
          height="315"
          src="https://www.youtube.com/embed/9-NX_3DfrF4?si=h_APy4T7o8mxMuG4"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        ></iframe>
      </div>
    </main>
  );
};
