import React, { useEffect, useRef, useState } from "react";
import { BlackButton } from "../../../components/black-button";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../api/api";
import { environment } from "../../../environments/environment";
import { useSelector } from "react-redux";
import { IAppState } from "../../../store/store";
import { Stack, Skeleton, TextField } from "@mui/material";
import { toast } from "react-toastify";
import moment from "moment";
import axios from "axios";
import { addScriptDefault } from "meta-pixel";
import TiktokPixel from "tiktok-pixel";

interface IComment {
  CreatedAt: string;
  campaign_uuid: string;
  media_url: string;
  text: string;
  name: string;
  uuid: string;
}

export const CampaignSingle_Comments = ({
  campaignBacked,
}: {
  campaignBacked: boolean;
}) => {
  const fbq = addScriptDefault();
  TiktokPixel.init(`${process.env.REACT_APP_TIKTOK_ID}`);
  const { id } = useParams();
  const navigate = useNavigate();
  const initialized = useRef(false);
  const fetchCampaignComments = async () => {
    try {
      // const response = await api.get(
      //   `${environment.baseUrl}/campaign/comment/fetch?campaign_uuid=${id}`
      // );
      fbq("trackCustom", "CommentOnCampaign", {
        campaign_id: id, // Track comment the campaign ID
      });
      TiktokPixel.track("CommentOnCampaign", {
        campaign_id: id, // Track comment the campaign ID
      });
      const response = await axios.get(
        `${environment.baseUrl}/campaign/comment/fetch?campaign_uuid=${id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setCampaignComments(response.data.data?.campaign_comments.rows ?? null);
    } catch (error) {}
  };

  const isLoggedIn = useSelector((state: IAppState) =>
    state.auth.token ? true : false
  );

  // useEffect(() => {
  //   if (!initialized.current) {
  //     initialized.current = true;

  //     if (isLoggedIn) fetchCampaignComments();
  //   }
  // }, [isLoggedIn]);

  useEffect(() => {
    fetchCampaignComments();
  }, []);

  const [campaignComments, setCampaignComments] = useState<IComment[] | null>(
    null
  );

  const [comment, setComment] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");

  const postComment = async () => {
    setIsLoading(true);
    try {
      const response = await api.post(
        `${environment.baseUrl}/campaign/comment/user`,
        {
          text: comment,
          campaign_uuid: id,
        }
      );

      if (response.data.success) {
        setComment("");
        fetchCampaignComments();
        toast.success(response.data.message);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const postCommentByUnregisteredUser = async () => {
    const commentPayload = {
      name,
      text: comment,
      campaign_uuid: id,
    };

    console.log(commentPayload);
    try {
      const response = await axios.post(
        `${environment.baseUrl}/campaign/comment`,
        commentPayload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.success) {
        console.log(response.data);
        setName("");
        setComment("");
        fetchCampaignComments();
        toast.success(response.data.message);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="max-w-[450px]">
      {/* <div className="flex flex-wrap gap-x-6 gap-y-4 justify-between items-center">
        {!campaignBacked && (
          <p className="text-sm">Only Backers Can Post Comments.</p>
        )}

        {!isLoggedIn && (
          <BlackButton
            onClick={() => navigate("/login")}
            text="Login"
            className="w-[150px]"
          ></BlackButton>
        )}
      </div> */}

      {campaignComments?.length ? (
        <div className="mt-4">
          <p className="text-sm">{campaignComments.length} Comments</p>

          <div className="max-h-[240px] mt-3 overflow-y-auto">
            {campaignComments?.map((comment, index) => (
              <div
                className={`px-4 py-5 flex items-center gap-6 ${
                  (index + 1) % 2 ? "bg-[#F8F8F8]" : ""
                }`}
              >
                {comment.media_url && (
                  <img
                    className="rounded-full w-[53px] h-[53px] object-cover"
                    src={comment.media_url}
                    alt=""
                  />
                )}

                <div className="flex-1">
                  <p className="text-sm font-bold">
                    {comment.name}{" "}
                    <span className="font-normal text-xs">
                      {moment(comment.CreatedAt).isSame(moment(), "day")
                        ? `Today ${moment(comment.CreatedAt).format("HH:mm")}`
                        : moment(comment.CreatedAt).format("YYYY-MM-DD HH:mm")}
                    </span>
                  </p>
                  <p className="text-xs">{comment.text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}

      {campaignComments?.length === 0 && (
        // temp
        <>
          <div className="border border-dashed text-center border-gray-400 mt-4 max-w-[450px] rounded-lg p-10">
            There are no comments yet!
          </div>
        </>
      )}

      {!campaignComments && isLoggedIn && (
        <>
          <Stack spacing={1} direction={"row"} className="mt-4">
            <Skeleton variant="rounded" width={400} height={80} />
            <Skeleton variant="rounded" width={400} height={80} />
            <Skeleton variant="rounded" width={400} height={80} />
            <Skeleton variant="rounded" width={400} height={80} />
            <Skeleton variant="rounded" width={400} height={80} />
          </Stack>
        </>
      )}

      {isLoggedIn ? (
        <div className="grid gap-4 mt-2">
          <TextField
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            className="admin_input_1 block_input admin_textarea"
            placeholder="Comment"
            multiline
            rows={3}
          />

          <BlackButton
            onClick={() => postComment()}
            text="Post"
            isLoading={isLoading}
            isDisabled={!comment.length || isLoading}
            className="w-[150px]"
          ></BlackButton>
        </div>
      ) : (
        <div className="grid gap-2 mt-2">
          <TextField
            placeholder="Enter your name"
            className="admin_input_1 block_input admin_textarea"
            required
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            className="admin_input_1 block_input admin_textarea"
            placeholder="Comment"
            multiline
            rows={3}
          />

          <BlackButton
            onClick={() => postCommentByUnregisteredUser()}
            text="Post"
            isLoading={isLoading}
            isDisabled={!comment.length || isLoading}
            className="w-[150px]"
          ></BlackButton>
        </div>
      )}
      {/* {isLoggedIn && campaignBacked && (
        <div className="grid gap-4 mt-2">
          <TextField
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            className="admin_input_1 block_input admin_textarea"
            placeholder="Comment"
            multiline
            rows={3}
          />

          <BlackButton
            onClick={() => postComment()}
            text="Post"
            isLoading={isLoading}
            isDisabled={!comment.length || isLoading}
            className="w-[150px]"
          ></BlackButton>
        </div>
      )} */}
    </div>
  );
};
