import React, { useState } from "react";
import { FiMail, FiUser, FiX } from "react-icons/fi";

interface MailchimpCampaginPopupProp {
  onClose: () => void;
}

const CampaignPopupForm: React.FC<MailchimpCampaginPopupProp> = ({
  onClose,
}) => {
  const [status, setStatus] = useState<"success" | "error" | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [email, setEmail] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");

  // const handleSubscribe = (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();

  //   console.log(firstName, lastName, email);
  // };
  const handleSubscribe = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    const postUrl = `${process.env.REACT_APP_MAILCHIMP_ACTION}?u=${
      process.env.REACT_APP_MAILCHIMP_U
    }&id=${process.env.REACT_APP_MAILCHIMP_ID}&EMAIL=${encodeURIComponent(
      email
    )}&MERGE1=${encodeURIComponent(firstName)}&MERGE2=${encodeURIComponent(
      lastName
    )}`;
    // console.log(postUrl);

    try {
      const response = await fetch(postUrl, {
        method: "POST",
        mode: "no-cors", // This mode allows the request but without returning a response
      });

      // Since "no-cors" mode doesn’t give us any response status, assume success.
      // setStatus("success");

      if (response.statusText === "") {
        setStatus("success");
        setMessage(
          "Thank you for subscribing! Please check your email for updates from us."
        );
        setTimeout(() => {
          onClose();
        }, 3000);
      } else {
        setStatus("error");
        setMessage("An error occurred, please try again later.");
      }
    } catch (error) {
      setStatus("error");
      setMessage("An error occurred, please try again later.");
    }
  };

  return (
    <div>
      <form className="space-y-4">
        <div className="relative">
          <FiUser className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            id="first_name"
            name="first_name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-xl focus:outline-none focus:ring-1 focus:ring-[#10152B]"
            placeholder="First Name"
          />
        </div>

        <div className="relative">
          <FiUser className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            id="last_name"
            name="last_name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-xl focus:outline-none focus:ring-1 focus:ring-[#10152B]"
            placeholder="Last Name"
          />
        </div>

        <div className="relative">
          <FiMail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-xl focus:outline-none focus:ring-1 focus:ring-[#10152B]"
            placeholder="Email"
          />
        </div>
        <button
          // type="submit"
          onClick={(e) => handleSubscribe(e)}
          className="w-full bg-[#10152B] text-white py-3 px-4 rounded-xl hover:bg-[#1c2647] focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition duration-300 ease-in-out transform hover:-translate-y-1"
        >
          Subscribe
        </button>
        {status && (
          <div
            className={`${
              status === "success"
                ? "bg-green-100 text-green-700"
                : "bg-red-100 text-red-700"
            } p-3 rounded-lg mb-4`}
          >
            {message}
          </div>
        )}
      </form>
    </div>
  );
};

const MailchimpCampaginPopup: React.FC<MailchimpCampaginPopupProp> = ({
  onClose,
}) => {
  const [status, setStatus] = useState<"success" | "error" | null>(null);

  // const [isVisible, setIsVisible] = useState(false);

  const mailchimpUrl = `${process.env.REACT_APP_MAILCHIMP_ACTION}?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;

  // const handleSubscribe = async (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();

  //   const postUrl = `${mailchimpUrl}&EMAIL=${encodeURIComponent(
  //     email
  //   )}&MERGE1=${encodeURIComponent(firstName)}&MERGE2=${encodeURIComponent(
  //     lastName
  //   )}`;
  //   console.log(postUrl);

  //   // fetch(postUrl, {
  //   //   method: "POST",
  //   //   mode: "no-cors",
  //   // })
  //   //   .then((response) => {
  //   //     console.log(response);
  //   //     if (response.statusText === "") {
  //   //       setStatus("success");
  //   //       setMessage(
  //   //         "Thank you for subscribing! Please check your email for updates from us."
  //   //       );
  //   //     } else {
  //   //       setStatus("error");
  //   //       setMessage("An error occurred, please try again later.");
  //   //     }
  //   //   })
  //   //   .catch(() => {
  //   //     setStatus("error");
  //   //     setMessage("An error occurred, please try again later.");
  //   //   });
  // };

  // const handleClose = () => {
  //   setIsVisible(false);
  // };

  // if (!isVisible) return null;

  const handleSubscribe = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4 sm:p-8 w-screen !overflow-x-hidden !overflow-y-hidden">
      <div className="bg-white p-6 sm:p-8 rounded-lg shadow-lg max-w-md w-full relative sm:mx-auto mx-4 sm:my-auto -mt-[20vh]">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
        >
          <FiX size={24} />
        </button>

        <h2 className="text-2xl sm:text-3xl font-bold text-center text-[#10152B] mb-4">
          JOIN OUR COMMUNITY
        </h2>
        <p className="text-gray-600 mb-6 text-center">
          Sign up to be the first to hear about our limited-edition collection
          and exclusive products when they go live.
        </p>

        <CampaignPopupForm onClose={onClose} />
      </div>
    </div>
  );
};

export default MailchimpCampaginPopup;
